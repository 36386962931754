import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc } from "firebase/firestore";
import { db } from "../../firebase"; // Import Firestore instance from your firebase configuration

interface User {
  id: string;
  name: string;
  email: string;
  groupId: string | null;
}

interface Group {
  id: string;
  groupId: string;
  recoveryPlan: string;
  Admin: string; // Admin is now a single email string
  Members: string[];
  password?: string;
}

interface MyGroup {
  onClose: () => void;
}

const MyGroup: React.FC<MyGroup> = ({ onClose }) => {
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [groupName, setGroupName] = useState('');
  const [recoveryPlan, setRecoveryPlan] = useState('');
  const [groupPassword, setGroupPassword] = useState('');
  const [users, setUsers] = useState<User[]>([]);
  const [filteredMembers, setFilteredMembers] = useState<User[]>([]);
  const [selectedAdmin, setSelectedAdmin] = useState<string | null>(null); // Single admin email
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [groups, setGroups] = useState<Group[]>([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const usersData: User[] = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        email: doc.data().email,
        groupId: doc.data().groupId || null,
      }));
      setUsers(usersData);
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      setGroupName(selectedGroup.groupId);
      setRecoveryPlan(selectedGroup.recoveryPlan);
      setGroupPassword(selectedGroup.password || '');

      const adminEmail = selectedGroup.Admin;
      const memberIds = new Set(users.filter(user => selectedGroup.Members.includes(user.email)).map(user => user.id));

      setSelectedAdmin(users.find(user => user.email === adminEmail)?.id || null);
      setFilteredMembers(users.filter(user => memberIds.has(user.id)));
    }
  }, [selectedGroup, users]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white h-5/6 w-11/12 lg:w-3/4 xl:w-2/3 p-8 rounded-3xl shadow-2xl relative overflow-auto">

        <button className="btn btn-sm btn-circle absolute right-2 top-2" onClick={onClose}>✕</button>

        <h2 className="text-3xl font-semibold text-gray-800 mb-6">View Group</h2>

        <div className="mb-6">
          <label className="block text-lg font-medium text-gray-700 mb-2">Select Group</label>
          <select
            value={selectedGroup?.id || ''}
            onChange={(e) => setSelectedGroup(groups.find(group => group.id === e.target.value) || null)}
            className="select select-bordered w-full"
            disabled // Disable the dropdown for view-only mode
            >
            <option value="" disabled>Select a group</option>
            {groups.map(group => (
              <option key={group.id} value={group.id}>{group.groupId}</option>
            ))}
          </select>
        </div>

        {selectedGroup && (
          <>
            <div className="mb-6">
              <label className="block text-lg font-medium text-gray-700 mb-2">Group Name</label>
              <input
                type="text"
                value={groupName}
                className="select select-bordered w-full"
                readOnly // Read-only for view mode
                />
            </div>

            <div className="mb-6">
              <label className="block text-lg font-medium text-gray-700 mb-2">Recovery Plan</label>
              <select
                value={recoveryPlan}
                className="select select-bordered w-full"
                disabled // Disable selection for view-only mode
                >
                <option value="" disabled>Select a recovery plan</option>
                {recoveryPlan && (
                  <option key={recoveryPlan} value={recoveryPlan}>{recoveryPlan}</option>
                )}
              </select>
            </div>

            <div className="mb-6">
              <label className="block text-lg font-medium text-gray-700 mb-2">Group Password</label>
              <input
                value={groupPassword}
                className="select select-bordered w-full"
                readOnly // Read-only for view mode
                />
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-medium text-gray-800 mb-4">View Members</h3>
              <input
                type="text"
                placeholder="Search members"
                value={searchQuery}
                onChange={handleSearch}
                className="select select-bordered w-full"
              />
              <div className="max-h-64 overflow-y-auto border border-gray-200 rounded-lg">
                {filteredMembers
                  .filter(user => (user.name.toLowerCase().includes(searchQuery) || user.email.toLowerCase().includes(searchQuery)))
                  .map((user) => (
                  <div key={user.id} className="flex items-center justify-between p-3 border-b border-gray-100 hover:bg-gray-50">
                    <div>
                      <p className="text-sm font-medium text-gray-900">{user.name}</p>
                      <p className="text-sm text-gray-500">{user.email}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MyGroup;