import React, { createContext, useContext, useState } from 'react';

export enum AdminLevel {
  SUPER_ADMIN,
  ADMIN,
  USER,
}

const UserLevelContext = createContext<{
  currentUserLevel: AdminLevel;
  setCurrentUserLevel: (level: AdminLevel) => void;
}>({
  currentUserLevel: AdminLevel.USER,
  setCurrentUserLevel: () => {},
});

export const UserLevelProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentUserLevel, setCurrentUserLevel] = useState<AdminLevel>(AdminLevel.USER);

  // Function to update the admin level
  const updateUserLevel = (level: AdminLevel) => {
    console.log(`Updating user level from ${currentUserLevel} to ${level}`);
    setCurrentUserLevel(level);
  };

  return (
    <UserLevelContext.Provider value={{ currentUserLevel, setCurrentUserLevel: updateUserLevel }}>
      {children}
    </UserLevelContext.Provider>
  );
};

export const useUserLevel = () => useContext(UserLevelContext);