import React, { useState, useEffect, useRef } from "react";
import { collection, getDoc, onSnapshot, doc, deleteDoc, query } from "firebase/firestore";
import { db } from "../../firebase"; // Import Firestore instance from your firebase configuration
import { useUserLevel, AdminLevel } from "../../admin";

// Define the notification type
type Notification = {
  id: string;
  date: string;
  type: string;
  user?: string;
  content: string;
};

interface NotificationProps {
  uid: string;
}

const Notifications: React.FC<NotificationProps> = ({ uid }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { currentUserLevel } = useUserLevel();
  const [groupId, setGroupId] = useState<string | null>(null);

  useEffect(() => {
    const fetchCurrentUserGroup = async () => {
      const userDoc = await getDoc(doc(db, 'users', uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setGroupId(userData['groupId']);
      }
    };

    fetchCurrentUserGroup();
  }, [uid, currentUserLevel]);

  useEffect(() => {
    if (!groupId) return;

    // Set up a Firestore listener to get real-time updates
    const q = query(collection(db, "notifications"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const notificationsData: Notification[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Notification[];

      // Super admins get all notifications, admins get their group application notifications
      if (currentUserLevel === AdminLevel.ADMIN) { 
        const relevantNotifications = notificationsData.filter((n) => n.content === groupId);

        setNotifications(relevantNotifications);
      } else if(currentUserLevel === AdminLevel.SUPER_ADMIN) {
        setNotifications(notificationsData);
      }

    }, (error) => {
      console.error("Error fetching notifications: ", error);
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, [groupId, currentUserLevel]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const deleteNotification = async (id: string) => {
    try {
      await deleteDoc(doc(db, "notifications", id));
      setNotifications(notifications.filter((notification) => notification.id !== id));
    } catch (error) {
      console.error("Error deleting notification: ", error);
    }
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button className="btn btn-ghost btn-circle" onClick={toggleDropdown}>
        <div className="indicator">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
            />
          </svg>
          {notifications.length > 0 && (
            <span className="badge badge-xs badge-primary indicator-item"></span>
          )}
        </div>
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-64 z-50 bg-white shadow-lg rounded-lg p-4">
          <ul>
            {notifications.length === 0 ? (
              <li className="text-gray-500">No notifications</li>
            ) : (
              notifications.map((notification, index) => (
                <li key={index} className="mb-2">
                  <div className="flex justify-between items-center">
                    <div>
                      <span className="font-semibold">
                        {notification.type === "group_join"
                          ? "New Group Member"
                          : notification.type === "new_user"
                          ? "New User Registered"
                          : "Notification"}
                      </span>
                      <span className="text-xs text-gray-400 ml-2">
                        {new Date(notification.date).toLocaleDateString()}
                      </span>
                    </div>
                    <button
                      className="text-red-500 text-xs"
                      onClick={() => deleteNotification(notification.id)}
                    >
                      Delete
                    </button>
                  </div>
                  <p className="text-sm text-gray-700">{notification.content}</p>
                  {notification.user && (
                    <p className="text-xs text-gray-500">From: {notification.user}</p>
                  )}
                  {index < notifications.length - 1 && <hr className="my-2" />}
                </li>
              ))
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Notifications;