import React, { useState } from 'react';
import NewGroup1 from './newGroup1';
import NewGroup2 from './newGroup2';
import ModifyGroup from './modifyGroup';
import ModifyMyGroup from './modifyMyGroup';
import MyGroup from './myGroup';
import { useUserLevel, AdminLevel } from '../../admin';
import { group } from 'console';
import { setGroupDataView } from '../../routes/userprofile';

interface GroupProps {
  email: string;
  id: string;
  isGroupMember: boolean;
  groupId: string | null | undefined;

}
const Group: React.FC<GroupProps> = ({id, isGroupMember, email, groupId}) => {
  const [visible, setVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const { currentUserLevel } = useUserLevel();

  const handleCancel = () => {
    setVisible(false);
    setSelectedOption(null);
  };

  const handleMenuClick = (option: string) => {
    setSelectedOption(option);
    setVisible(true);
  };

  switch(currentUserLevel){
    case AdminLevel.SUPER_ADMIN:
      return  <div>
      <div className="dropdown">
        <label tabIndex={0} className="btn mx-2 btn-inactive">Groups</label>
        <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-50">
          <li onClick={() => handleMenuClick('create')}><a>New Group</a></li>
          <li onClick={() => handleMenuClick('modify')}><a>Modify Groups</a></li>

        </ul>
      </div>

      {visible && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          {selectedOption === 'create' && <NewGroup2 onClose={handleCancel}/>}
          {selectedOption === 'modify' && <ModifyGroup onClose={handleCancel}/>}
        </div>
      )}
    </div>
    break;
    case AdminLevel.ADMIN:
      return  <div>
      <div className="dropdown">
        <label tabIndex={0} className="btn mx-2 btn-inactive">Groups</label>
        <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-50">
          <li onClick={() => handleMenuClick('modify')}><a>Modify My Group</a></li>

        </ul>
      </div>

      {visible && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
              {selectedOption === 'modify' && <ModifyMyGroup onClose={handleCancel} groupId={groupId} />}

        </div>
      )}
    </div>
    break;
    case AdminLevel.USER:
      return <div>
      <div className="dropdown">
        <label tabIndex={0} className="btn mx-2 btn-inactive">Groups</label>
        <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-50">
        <li onClick={() => handleMenuClick('create')}><a>New Group</a></li>
          {isGroupMember && (<li onClick={() => handleMenuClick('modify')}><a>My Group</a></li>) }
        </ul>
      </div>

      {visible && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
              {selectedOption === 'create' && <NewGroup1 onClose={handleCancel} email={email} userId={id}/>}
              {selectedOption === 'modify' && <MyGroup onClose={handleCancel} />}
        
        </div>
      )}
    </div>
    break
  }
};

export default Group;
