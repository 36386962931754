import React, { useState } from 'react';
import WorldTreatments from './worldTreatments';
import WorldAssessments from './worldAssessments';

interface WorldProps {
  tempData: Array<any>; // Adjust the type according to your user object structure
}

const World: React.FC<WorldProps> = ({ tempData }) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleMenuClick = (option: string) => {
    setSelectedOption(option);
  };

  const handleCancel = () => {
    setSelectedOption(null);
  };

  return (
    <div>
      <div className="dropdown">
        <label tabIndex={0} className="btn mx-2 btn-inactive">World</label>
        <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-50">
          <li onClick={() => handleMenuClick('worldTreatments')}>
            <a>Treatments</a>
          </li>
          <li onClick={() => handleMenuClick('worldAssessments')}>
            <a>Assessments</a>
          </li>
        </ul>
      </div>

      {selectedOption === 'worldTreatments' && (
        <WorldTreatments onClose={handleCancel} />
      )}
      {selectedOption === 'worldAssessments' && (
        <WorldAssessments onClose={handleCancel} />
      )}
    </div>
  );
};

export default World;