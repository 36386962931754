import React, { useState, useEffect } from 'react';
import { collection, doc, getDocs, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Import Firestore instance from your firebase configuration

interface ModifySequenceProps {
  onModify: (productCode: string, sequence: any) => void;
}

const ModifySequence: React.FC<ModifySequenceProps> = ({ onModify }) => {
  const [sequences, setSequences] = useState<{ [key: string]: any[] }>({});

  useEffect(() => {
    const fetchSequences = async () => {
      const sequencesCollection = collection(db, 'sequences');
      const sequencesSnapshot = await getDocs(sequencesCollection);
      const sequencesData: { [key: string]: any[] } = {};

      sequencesSnapshot.forEach(doc => {
        sequencesData[doc.id] = [];
        const sequences = doc.data();
        Object.keys(sequences).forEach(key => {
          if (key.startsWith('sequence')) {
            sequencesData[doc.id].push({ id: key, ...sequences[key] });
          }
        });
      });

      setSequences(sequencesData);
    };

    fetchSequences();
  }, []);

  const handleDelete = async (productCode: string, sequenceId: string) => {
    const sequenceDoc = doc(db, 'sequences', productCode);
    const snapshot = await getDoc(sequenceDoc);
    if (snapshot.exists()) {
      const sequences = snapshot.data();
      delete sequences[sequenceId];
      await setDoc(sequenceDoc, sequences);
      setSequences(prev => ({
        ...prev,
        [productCode]: prev[productCode].filter(seq => seq.id !== sequenceId)
      }));
    }
  };

  return (
    <div className="p-6 bg-white rounded-md">
    <h2 className="text-2xl font-bold mb-6">Modify Sequences</h2>
    <div className="overflow-y-auto max-h-96">
      {Object.keys(sequences).map((productCode) => (
        <div key={productCode} className="mb-6">
          <h3 className="text-xl font-semibold mb-4">{productCode}</h3>
          <ul className="list-disc pl-6 space-y-4">
            {sequences[productCode].map((sequence) => (
              <li key={sequence.id} className="mb-4">
                <div className="flex flex-col bg-gray-50 p-4 rounded-md shadow-sm">
                  <span><strong>Product Code:</strong> {sequence.productCode}</span>
                  <span><strong>Index:</strong> {sequence.index}</span>
                  <span><strong>Repeat:</strong> {sequence.repeat}</span>
                  <div><strong>Phases:</strong></div>
                  <ul className="pl-4 space-y-2">
                    {['phase1', 'phase2', 'phase3', 'phase4', 'phase5'].map((phaseKey, idx) => {
                      const phase = sequence[phaseKey];
                      return (
                        <li key={phaseKey} className="mb-1">
                          <strong>Phase {idx + 1}:</strong>
                          <div className="pl-2">
                            <span>Pattern: {phase.pattern}</span>, 
                            <span> Intensity: {phase.intensity}</span>, 
                            <span> Length: {phase.length}</span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="flex mt-4 space-x-4">
                    <button
                      onClick={() => onModify(productCode, sequence)}
                      className="btn btn-sm w-30"
                    >
                      Modify
                    </button>
                    <button
                      onClick={() => handleDelete(productCode, sequence.id)}
                      className="btn  btn-sm w-30"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  </div>
);
};

export default ModifySequence;
