import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { signInUser } from "../firebase";

type Inputs = {
  email: string,
  password: string,
};

export default function Login() {

  const { currentUser, loading } = useContext(AuthContext)

  const [loginError, setLoginError] = useState<string | null>(null)

  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();

  const [formLoading, setFormLoading] = useState<boolean>(false)

  const navigate = useNavigate()
  const location = useLocation()

  // Check if the current user exists on the initial render.
  useEffect(() => {
    if (currentUser) {
      if (location.state && location.state.from) {
        navigate(location.state.from as string)
      } else {
        navigate('/')
      }
    }
  }, [currentUser])

  const onSubmit: SubmitHandler<Inputs> = data => {
    setFormLoading(true)
    signInUser(data.email, data.password)
      .catch((error: any) => {
        console.log([error])
        if (error.code == 'auth/invalid-credential') {
          error.message = 'The email or password is incorrect.'
        } else if (error.code == 'auth/invalid-email') {
          error.message = 'The email address is badly formatted.'
        } else {
          error.message = 'An unknown error occurred.'
        }
        setLoginError(error.message)
      }).finally(() => {
        setFormLoading(false)
      })
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <span className="loading loading-infinity loading-lg"></span>
      </div>
    )
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="card w-96 bg-base-100 shadow-xl grid place-items-center">
        <div className="card-body items-center text-center w-full">
          <h2 className="card-title">Login</h2>
          {loginError && <div className="alert alert-error">{loginError}</div>}
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <div className="form-control">
              <label className="label">
                <span className="label-text">Email</span>
              </label>
              <input
                type="text" placeholder="email"
                className={`input input-bordered ${errors.email && 'input-error'}`}
                defaultValue=""
                {...register("email", { required: true })}
              />
              <div className="label">
                <span
                  className="label-text-alt text-error"
                >{errors.email ? <span>This field is required</span> : '\u00A0'}</span>
              </div>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Password</span>
              </label>
              <input
                type="password"
                placeholder="password"
                className={`input input-bordered ${errors.email && 'input-error'}`}
                defaultValue=""
                {...register("password", { required: true })}
              />
              <div className="label">
              <span
                className="label-text-alt text-error"
              >{errors.password ? <span>This field is required</span> : '\u00A0'}</span>
              </div>
            </div>
            <button type="submit" className="btn btn-primary mt-5" disabled={formLoading}>
              {formLoading && <span className="loading loading-spinner"></span>}
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}