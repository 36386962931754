import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Root from "./routes/root";
import Login from "./routes/login";
import { AuthProvider } from './context/auth';
import UserProfile from './routes/userprofile';
import { UserLevelProvider } from './admin';

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "user/:id",
        element: <UserProfile />,
      },
    ],
  },
]);


ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
).render(
  <UserLevelProvider>
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider data-theme="light" router={router} />
    </AuthProvider>
  </React.StrictMode>
  </UserLevelProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
