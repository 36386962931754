import React, { useState, useEffect } from "react";
import { collection, doc, getDoc, setDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

interface AssessmentsProps {
  onClose: () => void;
}

const Assessments: React.FC<AssessmentsProps> = ({ onClose }) => {
  const [kneeUsers, setKneeUsers] = useState<string[]>([]);
  const [allUsers, setAllUsers] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [newUser, setNewUser] = useState("");

  useEffect(() => {
    const fetchKneeUsers = async () => {
      const docRef = doc(db, "knee_demo_users", "knee_demo_users");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setKneeUsers(data ? data.knee_demo_users : []);
      }
    };

    const fetchAllUsers = async () => {
      const usersCollection = collection(db, "users");
      const usersSnapshot = await getDocs(usersCollection);
      const usersData = usersSnapshot.docs.map(doc => doc.data().email);
      setAllUsers(usersData as string[]);
    };

    fetchKneeUsers();
    fetchAllUsers();
  }, []);

  const handleAddUser = (email: string) => {
    if (!kneeUsers.includes(email)) {
      const updatedUsers = [...kneeUsers, email];
      setKneeUsers(updatedUsers);
      saveToFirestore(updatedUsers);
    }
  };

  const handleRemoveUser = (email: string) => {
    const updatedUsers = kneeUsers.filter(user => user !== email);
    setKneeUsers(updatedUsers);
    saveToFirestore(updatedUsers);
  };

  const saveToFirestore = async (updatedUsers: string[]) => {
    const docRef = doc(db, "knee_demo_users", "knee_demo_users");
    await setDoc(docRef, { knee_demo_users: updatedUsers });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredAllUsers = allUsers.filter(user => user.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white h-5/6 w-11/12 lg:w-3/4 xl:w-2/3 p-8 rounded-3xl shadow-2xl relative overflow-auto">

        <button className="btn btn-sm btn-circle absolute right-2 top-2" onClick={onClose}>✕</button>

        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Knee Assessment Users</h2>

        {/* Search Bar */}
        <div className="mb-6">
          <input
            type="text"
            placeholder="Search users"
            value={searchQuery}
            onChange={handleSearch}
            className="input input-bordered w-full mb-4"
          />
        </div>

        {/* Add new user manually */}
        <div className="mb-6 flex">
          <input
            type="email"
            placeholder="Add new user by email"
            value={newUser}
            onChange={(e) => setNewUser(e.target.value)}
            className="input input-bordered w-full"
          />
          <button
            onClick={() => {
              handleAddUser(newUser);
              setNewUser("");
            }}
            className="btn btn-primary ml-4"
          >
            Add
          </button>
        </div>

        {/* User List */}
        <div className="mb-6">
          <h3 className="text-lg font-medium mb-4">Available Users</h3>
          <ul className="max-h-64 overflow-y-auto border border-gray-200 rounded-lg">
            {filteredAllUsers.map(user => (
              <li key={user} className="flex items-center justify-between p-3 border-b border-gray-100 hover:bg-gray-50">
                <div className="text-gray-900">{user}</div>
                <div className="flex gap-4">
                  <input
                    type="checkbox"
                    checked={kneeUsers.includes(user)}
                    onChange={() => kneeUsers.includes(user) ? handleRemoveUser(user) : handleAddUser(user)}
                    className="checkbox checkbox-primary"
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Current Knee Assessment Users */}
        <div className="mb-6">
          <h3 className="text-lg font-medium mb-4">Current Knee Assessment Users</h3>
          <ul className="max-h-64 overflow-y-auto border border-gray-200 rounded-lg">
            {kneeUsers.map(user => (
              <li key={user} className="flex items-center justify-between p-3 border-b border-gray-100 hover:bg-gray-50">
                <div className="text-gray-900">{user}</div>
                <button
                  className="btn btn-link btn-sm text-red-600"
                  onClick={() => handleRemoveUser(user)}
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="btn btn-secondary"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Assessments;