// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore, initializeFirestore, persistentLocalCache } from "firebase/firestore";
import { NextOrObserver, User, getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getFunctions } from 'firebase/functions';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD7l0DCRRJpl0FwVeE1h6kJOVqEAbfRdCQ",
  authDomain: "myovolt-9af95.firebaseapp.com",
  projectId: "myovolt-9af95",
  storageBucket: "myovolt-9af95.appspot.com",
  messagingSenderId: "98964236888",
  appId: "1:98964236888:web:15a1906d60495635cbf82d",
  measurementId: "G-2EM4JPDM2E"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Functions
const functions = getFunctions(app);
export { functions };

// export const db = getFirestore(app);
export const db = initializeFirestore(app, {localCache: persistentLocalCache(/*settings*/{})});
export const auth = getAuth(app);

// connectFirestoreEmulator(db, '127.0.0.1', 8088);

export const signInUser = async (
  email: string, 
  password: string
) => {
  if (!email && !password) return;

  return await signInWithEmailAndPassword(auth, email, password)
}

export const userStateListener = (callback:NextOrObserver<User>) => {
  return onAuthStateChanged(auth, callback)
}

export const SignOutUser = async () => await signOut(auth);

export const getSource = (id: string) => {
  let localStorageTime = localStorage.getItem(id);
  let currentTime = new Date().getTime();
  let isLocalStorageExpired = localStorageTime ? currentTime > new Date(localStorageTime).getTime() : true;
  let source: "server" | "cache" = isLocalStorageExpired ? "server" : "cache";
  
  if (source === "server") {
      const expirationTime = new Date().getTime() + 60 * 60 * 1000; // 1 hour from now
      localStorage.setItem(id, new Date(expirationTime).toUTCString());
  }
  return source;
};
